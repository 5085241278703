import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventEnter]',
  standalone: true
})
export class PreventEnterDirective {
  // Generally prevent enter key. Allow it selectively for
  // textarea line break
  // buttons type="button" in order to trigger their funtions
  // and finally for button type="submit" if it is triggered by key event
  @HostListener('keydown.enter', ['$event']) onKeydownEnter($event) {
    const tagName = $event.target.tagName.toLowerCase();

    if (tagName !== 'textarea' && tagName !== 'button') {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }
}
